.mask{
    width: 100%;
    height: 100vh;
}
.intro-img{
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
object-fit: cover;
}
.gif-img{
    width:20%;
    height: 20%;
}

.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(18, 13, 13);
    opacity: 0.7;
}

.hero{
    width: 100%;
    height: 100%;
}
.hero .content{
    margin-top: 2.5rem;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1{
    font-size: 4rem;
    padding: 0.4rem 0 1.5rem;
}
.hero .content h2{
    color: azure;
    font-size: 2rem;
    padding: 0.3rem 0 0.5rem;
}
.hero .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
    padding: 0.6rem 0 1.5rem;
}
 .content .btn{
    margin: 1rem 0.3rem;
 }

 @media screen and (max-width:640px) {
    .hero .content h1{
        font-size: 2rem;
        
    }
    .hero .content p{
        font-size: 0.8rem;
       
    }
    .hero .content h2{
       
        font-size: 1rem;
       
    }
   
    
 }