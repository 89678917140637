.about{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.about .left{
    text-align: center;
    margin-right: 5.5rem;
    padding: 1rem;
    max-width: 350px;
}
.about .left p{
    margin: 1.2rem 0;
}
.about .left .btn{
    margin: 1rem 0;
}
.about .right{
    max-width: 600px;
}
.right .img-container{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img{
    max-width: 90%;
    border: 1px solid #333;
}

.right .top{
    grid-column: 1/span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}